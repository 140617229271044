<template lang="pug">
  .w-dresscode
    img(src="@/assets/img/colors-bg.jpg")
    .w-dresscode__content
      .w-dresscode__decor
        flower-branch-icon
      p(v-html="$t('colors.text_1')")
      .w-dresscode__colors
        .w-dresscode__color(
          v-for="(item, i) in colors"
          :key="i"
          :style="{ backgroundColor: item }")
</template>

<script>
export default {
  name: 'DresscodeWidget',

  data: () => ({
    colors: [
      '#F3E0D9',
      '#EADADB',
      '#A4ADB2',
      '#AFBAA9',
      '#44846A'
    ]
  })
}
</script>

<style lang="scss">
  .w-dresscode {
    position: relative;

    &__decor {
      margin-bottom: 3rem;
    }

    &__content {
      position: absolute;
      top: 7.5rem;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      z-index: 1;
    }

    &__colors {
      display: flex;
      margin-top: 2rem;
    }

    &__color {
      height: 2.2rem;
      width: 2.2rem;
      border-radius: 50%;

      &:not(:last-child) {
        margin-right: .8rem;
      }
    }
  }
</style>
