<template lang="pug">
  .w-countdown.widget
    img(src="@/assets/img/countdown-bg.jpg")
    v-animate
      .w-countdown__content
        .w-countdown__time
          .w-countdown__time-item
            .w-countdown__time-value {{ days | addzero }}
            .w-countdown__time-label {{ $t('countdown.days') }}
          .w-countdown__time-item
            .w-countdown__time-value {{ hours | addzero }}
            .w-countdown__time-label {{ $t('countdown.hours') }}
          .w-countdown__time-item
            .w-countdown__time-value {{ min | addzero }}
            .w-countdown__time-label {{ $t('countdown.minutes') }}
          .w-countdown__time-item
            .w-countdown__time-value {{ seconds | addzero }}
            .w-countdown__time-label {{ $t('countdown.seconds') }}
        .w-countdown__ps {{ $t('countdown.ps') }}
</template>

<script>
const DEADLINE = new Date('2022-02-05T13:30')

export default {
  name: 'CountdownWidget',

  data: () => ({
    interval: null,
    seconds: 0,
    min: 0,
    hours: 0,
    days: 0,
    inited: false,
    done: false
  }),

  filters: {
    addzero (value) {
      return value < 10
        ? `0${value}`
        : value
    }
  },

  mounted () {
    this.interval = setInterval(this.updateTimer, 1000)
  },

  destroyed () {
    clearInterval(this.interval)
  },

  methods: {
    updateTimer () {
      const now = new Date()
      const s = (DEADLINE - now) / 1000
      if (s > 1) {
        this.seconds = Math.floor(s % 60)
        this.min = Math.floor((s / 60) % 60)
        this.hours = Math.floor((s / 60 / 60) % 24)
        this.days = Math.floor(s / 60 / 60 / 24)
      } else {
        this.stopTimer()
      }
      this.inited = true
    },

    stopTimer () {
      clearInterval(this.interval)
      this.seconds = 0
      this.min = 0
      this.hours = 0
      this.days = 0
      this.done = true
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '../../sass/abstracts/_variables.scss';

  .w-countdown {
    z-index: 1;

    &__content {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    &__time {
      display: flex;
      justify-content: space-around;
      margin-bottom: 7rem;
    }

    &__time-item {
      color: $color-white;
      display: flex;
      flex-direction: column;
      align-items: center;
      min-width: 9rem;
    }

    &__time-value {
      opacity: .9;
      font-size: 6rem;
      font-family: 'Aire Bold Pro';
    }

    &__time-label {
      opacity: .7;
      color: #FBF9ED;
      font-size: 1.4rem;
      letter-spacing: 2px;
      font-family: 'Forum';
      text-transform: uppercase;
    }

    &__ps {
      color: $color-white;
      text-align: center;
      font-size: 2.8rem;
      font-style: normal;
      font-weight: normal;
      font-family: 'Scriptorama';
    }

    &__icon {
      color: $color-white;
    }
  }
</style>
