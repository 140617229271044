<template lang="pug">
  .widget
    v-animate
      hooper
        slide
          img.slide-img(src="@/assets/img/slider-01.jpg")
        slide
          img.slide-img(src="@/assets/img/slider-02.jpg")
        slide
          img.slide-img(src="@/assets/img/slider-03.jpg")
        slide
          img.slide-img(src="@/assets/img/slider-05.jpg")
        slide
          img.slide-img(src="@/assets/img/slider-06.jpg")
        slide
          img.slide-img(src="@/assets/img/slider-07.jpg")
        slide
          img.slide-img(src="@/assets/img/slider-08.jpg")
        slide
          img.slide-img(src="@/assets/img/slider-09.jpg")
        hooper-navigation(slot="hooper-addons")
</template>

<script>
import 'hooper/dist/hooper.css'
import { Hooper, Slide, Navigation as HooperNavigation } from 'hooper'

export default {
  name: 'SliderWidget',

  components: {
    Hooper,
    Slide,
    HooperNavigation
  },

  data: () => ({
    hooperSettings: {
      itemsToShow: 1,
      centerMode: true
    }
  })
}
</script>

<style lang="scss">
  .hooper {
    height: auto;

    .icon {
      fill: white;
    }
  }
</style>
