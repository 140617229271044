<template lang="pug">
  .w-present
    img.w-present__icon(src="@/assets/svg/present.svg")
    p(v-html="$t('present.text_1')")
    p.last-p(v-html="$t('present.text_8')")
    v-dropdown(center)
      button.btn {{ $t('present.text_2') }}
      template(slot="content")
        .w-present__card
          .w-present__item
            p {{ $t('present.text_3') }}
            p {{ $t('present.text_4') }}
            p {{ $t('present.text_5') }}
            p 04/26
          .w-present__item
            p {{ $t('present.text_6') }}
            P {{ $t('present.text_7') }}
            P {{ $t('present.text_9') }}
            P {{ $t('present.text_10') }}
</template>

<script>
export default {
  name: 'PresentWidget'
}
</script>

<style lang="scss">
  @import '../../sass/abstracts/_variables.scss';

  .w-present {
    padding: 16rem 0;
    display: flex;
    align-items: center;
    flex-direction: column;
    background-color: $color-secondary;

    &__icon {
      width: 4rem;
      margin-bottom: 2.5rem;
    }

    &__card {
      background-color: $color-background;
      border-radius: 1rem;
      padding: 1.4rem;
      width: 29rem;
      box-shadow: 0 0 2.4rem rgba(12, 5, 2, .2);
    }

    &__item {
      border-radius: 1rem;
      text-align: center;
      font-size: 1.6rem;
      padding: 1.4rem;
      border: .1rem solid $color-secondary;

      &:not(:last-child) {
        margin-bottom: 1.4rem;
      }

      &:nth-child(3) {
        margin-bottom: 3rem;
      }

      p {
        user-select: text;
        -webkit-user-select: text;
        -khtml-user-select: text;
        -moz-user-select: text;
        -ms-user-select: text;
        text-transform: unset;

        &:not(:last-child) {
          margin-bottom: 1.5rem;
        }
      }
    }

    .btn {
      color: $color-primary;
      background-color: $color-background;
    }

    p {
      margin-bottom: 1.5rem;
    }

    .last-p {
      margin-bottom: 3rem;
    }
  }
</style>
