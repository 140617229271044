<template lang="pug">
  .w-address.widget
    v-animate
      img.w-address__decor-right(
        src="@/assets/illustrations/flower-light.svg")
      img.w-address__decor-left(
        src="@/assets/illustrations/flower-light.svg")
    .w-address__content
      .title(v-html="$t('address.text_1')")
      p.w-address__text(v-html="$t('address.text_2')")
      a.w-address__link(
        target="_blank"
        href="https://goo.gl/maps/zZ3hMhywUXRD76MJ8")
        | {{ $t('address.text_3') }}
      .title(v-html="$t('address.text_4')")
      p.w-address__text(v-html="$t('address.text_5')")
      a.w-address__link(
        target="_blank"
        href="https://goo.gl/maps/1PJYCbwaivbL7WAMA")
        | {{ $t('address.text_3') }}
</template>

<script>
export default {
  name: 'AddressWidget'
}
</script>

<style lang="scss">
  @import '../../sass/abstracts/_variables.scss';

  .w-address {
    position: relative;
    background-color: $color-address-widget-bg;

    .title {
      font-size: 3.4rem;
      line-height: 3rem;
    }

    &__text {
      margin-top: 1rem;
      margin-bottom: 2rem;
      text-align: left !important;
    }

    &__link {
      display: inline-block;
      color: $color-primary;
      background-color: $color-background;
      font-size: 1.4rem;
      padding: 1.4rem 3rem;
      border-radius: 1rem;
      text-decoration: none;
      text-transform: uppercase;

      &:visited {
        color: $color-primary;
      }

      &:not(:last-child) {
        margin-bottom: 6rem;
      }
    }

    &__decor-right {
      position: absolute;
      top: -26rem;
      right: -8rem;
      width: 100%;
      transform: rotate(-180deg);
      z-index: 0;
    }

    &__decor-left {
      position: absolute;
      left: -1rem;
      bottom: -28rem;
      width: 105%;
      transform: rotate(-9.52deg);
      z-index: 0;
    }

    &__content {
      position: relative;
      padding: 20rem 4.5rem;
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      z-index: 1;
    }
  }
</style>
