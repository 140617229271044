<template lang="pug">
  .w-alco
    p.w-alco__text(v-html="$t('alco.text_1')")
</template>

<script>
export default {
  name: 'AlcoWidget'
}
</script>

<style lang="scss">
  .w-alco {
    padding: 14rem 0;
  }
</style>
